@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

:root {
  --primary: #0E4672;
  --white: #FFFF;
  --shadow: 0px 4px 4px -2px rgba(14, 70, 114, 0.3);
}


:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

html {
  scroll-behavior: smooth;
}

.bluetext {
  color: #0E4672 !important;
}

body {
  margin: 0;
  padding: 0;
  background: #F3F3F3 !important;
  /* font-size: 14px; */
  overflow-x: hidden;
  font-family: 'DM Sans', sans-serif !important;
}

/*===custome scrollbar===*/
::-webkit-scrollbar {
  width: 0.625rem;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background: #0B2D48;
}

.pointer {
  cursor: pointer;
}

/* ---btn---- */
.btn:hover,
.btn:active {
  color: #000;
  /* border: 2px solid #E6E6E6; */

}



.backcolor {
  background-color: #167CB9;
}

/* Override */

h1 {
  font-style: normal;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.8125rem;
}

h2 {
  font-style: normal;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.625rem;
}

h3 {
  font-style: normal;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

h4 {
  font-style: normal;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
}

h5 {
  font-style: normal;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.125rem;
}

h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 29px;
}

.btn-primary {
  background-color: #0B2D48;
  font-size: 18px;
  color: #FFFFFF;
  border: unset;
  border-radius: 5px;
}

.btn-home-book {
  background: #0B2D48;
  border-radius: 0.5rem;
  border: none;
  font-size: 18px;
  line-height: 23px;
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
}

.btn-select {
  background: #FFFFFF;
  border: 1px solid #1F3648;
  border-radius: 5px;
  color: #1F3648;
  font-size: 16px;
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  min-width: 110px;
}

.btn-outline-default {
  --bs-btn-color: #0E4672;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #0E4672;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #0E4672;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0E4672;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
  border: none;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: var(--primary);
  color: white;
}

.payment-mehtod-button {
  border: 2px solid #E6E6E6;
}

.btn-cash {
  background: #1F3648;
  border-radius: 4px;
  font-style: normal;
  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  color: #FFFFFF;
}

.btn-stripe,
.btn-paypal {
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
}

.white-card {
  background: #FFFFFF;
  border-bottom: 2px solid #E0E0E0;
  box-shadow: var(--shadow);
  /* padding-bottom: 4rem; */
}

.payment-section {
  background: #F9FCFF;
  height: 503px;
}

.payment-p {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}

.vertical-line {
  margin-top: 20px;
  border-left: 2px solid slategrey;
  height: 240px;
}

@media screen and (max-width: 768px) {
  .vertical-line {
    /* display: none; */
    border-left: 2px solid slategrey;
    background: slategrey;
    height: 2px;
    width: 90%;
    margin-left: 4%;

  }
}

.span {
  color: #0b74c5;
  font-size: 18px;
  font-weight: 700;
}

.span-p {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #0E4672;
}

.span-p1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #455E72;
}

.btn-back-home {
  border: none;
  background: #0E4672;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
}

.span-text {
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  font-family: DM Sans;
  line-height: normal;
  color: #000000;
  width: 55.375rem;
}

.p-text {
  font-family: DM Sans;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
}

.p-ltext {
  color: #1F3648;
  font-family: DM Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.how-p-text {
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.advantage-banner {
  width: 100%;
  background: url('./Assets/Images/home2.png') no-repeat right;
  background-size: contain;
  /* padding: 8.75rem 0 3.25rem; */
}

.p-advantage {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 550;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.span-confirm {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
}

.airport-banner {
  width: 100%;
  background: url('./Assets/Images/undraw_order_ride_re_372k.svg') no-repeat left;
  background-size: contain;
  padding: 60px;
}

/*------ header-link------- */
.link {
  text-decoration: none;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 15.6592px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
}

.link:hover {
  color: #000000;
}


.link-select {
  text-decoration: none;
  color: #0d0d0e;
  font-style: normal;
  font-weight: 500;
  font-size: 15.6592px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
}

.link-select:hover {
  color: #167CB9;
}

.footer-link:hover {
  color: #f2f5f7 !important;
}

.timeDate {
  position: relative;
  left: -10px;
}

.vehicle-edit-btn {
  border: none;
  padding: 4px 20px;
  border-radius: 39px;
  background: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1F3648;
}

.summary-edit-btn {
  background: #E9F5FF;
  border: none;
  padding: 5px 20px;
  border-radius: 39px;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  color: #1F3648;
}

.extraoption-btn {
  width: 207px;
  height: 55px;
}

.extraoption-btn:active {
  background: #0D304B;
}


.p {
  font-style: normal;
  font-weight: 700;
  font-size: 18.7236px;
  line-height: 33px;
  color: #000000;

}

.summary-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.summary-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 26px;
  color: #000000;
}

.vehicle-text {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #455E72;
}

.message-img {
  margin-left: 145px;
}

.veh-price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #000000;
}

.col.text-white.d-flex.mx-5.styleShadow {
  box-shadow: -1px 37px 0px 10px #0F3756;
}

.shadow2 {
  box-shadow: 0px 39px 1px -17px #0f3756
}

.shadow22 {
  box-shadow: 0px 44px 1px 10px #0f3756
}

.text-position {
  bottom: 10px;
  left: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 24.4889px;
  line-height: 31px;
  color: #000000;
}


.footer-text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 38px;
  color: #FFFFFF
}


.footer-text-header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20.4037px;
  line-height: 36px;
  white-space: nowrap;
  color: #FFFFFF;
}

.footer-blog-text {
  /* font-family: 'DM Sans'; */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14.8677px;
  line-height: 26px;
  color: #FFFFFF;
}

.footer-link {
  text-decoration: none;
  color: #97ADBE;
  font-style: normal;
  font-weight: 500;
  font-size: 15.6592px;
  line-height: 20px;
  text-align: start;
  /* white-space: nowrap; */
}

/* h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 26.2333px;
  line-height: 46px;
  margin: revert;
} */

b {
  font-size: 17px
}

.label-b {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.location-num {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #FFFFFF;
}

.avtar-position {
  position: relative;
  left: -125px;
  top: 36px;
}

.btn-update-profile {
  border: none;
  background: #0E4672;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.btn-export-data {
  border: none;
  background: #0E4672;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13.5px;
  line-height: 18px;
  color: #FFFFFF;
}

.css-kzmnnf-MuiTableCell-root {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14.5px !important;
  line-height: 19px !important;
  color: #1F3648 !important;
}

.css-1ioet89-MuiTableCell-root {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14.5px !important;
  line-height: 19px !important;
  color: #1F3648 !important;
}

.css-1n815sy-MuiTableCell-root {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16.0875px !important;
  line-height: 21px !important;
  color: #1F3648 !important;
}

/* Over Ride Stepper */
.MuiStepLabel-root .MuiSvgIcon-root {
  font-size: 3rem;
  font-family: 'DM Sans';
  color: var(--primary);
}

.MuiStepLabel-root .MuiSvgIcon-root .MuiStepIcon-text {
  color: var(--primary);
}

.css-15fhj8j-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
}


.puls-minus-btn-darkblue {
  /* border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem; */
  /* background-color: #1F3648; */
  background-color: #C2DDF2;
  border-radius: 42px 0 0 42px;
  text-align: center;
  color: #1F3648;
  /* color: #FFFFFF; */
  /* padding: 3px; */
  font-size: 1.5rem;
  /* font-family: 'DM Sans' */
}

.puls-minus-btn-skyblue {
  /* border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem; */
  border-radius: 0 42px 42px 0;
  background-color: #1F3648;
  /* color: #1F3648; */
  color: #FFFFFF;
  text-align: center;
  padding: 3px;
  font-size: 1.5rem
}

.Gesamt-text {
  color: #0E4672;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.btn-coupon-code {
  background: rgba(14, 70, 114, 0.32);
  border-radius: 5px;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(31, 54, 72, 0.27);
  ;
}

.btn-coupon-code:hover {
  background: rgba(14, 70, 114, 0.32);
}

.btn-payment {
  background: #0E4672;
  border-radius: 5px;
  border: unset;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.btn-booking {
  background: #F9FCFF;
  border-radius: 5px;
  border: 1px solid #0E4672;
  color: #0E4672;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

.card-text {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 31px;
  color: #000000;

}

.child-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.child-price {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: #0E4672;
}

.uroo {
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #000000;
}

.whatsapp-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

/*  */
.vehicle-option-sider-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;

}

.vehicle-card-text {
  font-family: 'DM Sans';
  font-Weight: 500;
  font-size: 18px;
  Line-height: 23.44px;
  color: #0E4672;
}

.thanku {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  color: #1F3648;
}

/* .custom-select:focus {
} */
/* outline: none; */


/* summary component textfield */
.custom-textfield input {
  color: #fff;

}

/* .custom-textfield .Mui-focused {
  color: #17A2B8 !important;
  font-size: large;
  font-weight: 500;
} */

.custom-textfield .MuiInputBase-root:after {
  border-bottom: white;

}

.custom-textfield label {
  color: #fff;

}

.select {
  border: 0px;
  outline: 0px;
  Border: none;
}

.select option {
  Border: none;
}

/* ------------------Admin booking page------------------ */
.admin-booking {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  color: #1F3648;
}

.label-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #ffffff;
  padding: 10px;
  margin-top: 20px;
}

.heading-section {
  /* font-family:DM Sans; */
  /* width: 1741px; */
  height: 55px;
  background: #1F3648;
  color: #fff;
  padding: 16px;
  font-size: large;
}

.label-admin-text {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 18px;
  color: #455E72;
  margin-bottom: 10px;
}

.text-admin {
  /* font-family: DM Sans; */
  font-size: 20px;
  font-weight: 590;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F3648;
}

.table-th {
  background-color: #F1F9FF;
}

/* driver-register */
.driver-heading {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 52px;
  color: #0E4672;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media screen and (max-width: 763px) {
  .footmargin {
    margin-top: 6rem !important;
  }
}

.whatsapp-logo {
  /* width: 100px; */
  transition: width 2s;
  transition-timing-function: ease-in-out;
  width: 60px;
  position: fixed;
  top: 85vh;
  cursor: pointer;
  left: 93%;
  z-index: 108;
}

.whatsapp-logo:hover {
  width: 90px;
}

@media screen and (max-width: 763px) {
  .whatsapp-logo {
    /* width: 100px; */
    transition: width 2s;
    transition-timing-function: ease-in-out;
    width: 70px;
    position: fixed;
    top: 90vh;
    cursor: pointer;
    left: 75%;
  }
}


@media screen and (max-width: 500px) {
  .home-call {
    display: block;
    margin: auto;
    padding-bottom: 5px !important;
  }
}
@media screen and (max-width: 500px) {
  .num {
   font-size: 2rem;
  }
}


.question {
  /* font-family: 'DM Sans'; */
  /* font-style: normal; */
  font-weight: 600;
  /* font-size: 32px; */
  /* line-height: 52px; */
  color: #0E4672;
}

.text-driver {
  /* font-family: DM Sans; */
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000
}

.drive-step {
  /* font-family: DM Sans; */
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E4672
}

.drive-step-content {
  /* font-family: DM Sans; */
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E4672
}

/* @media screen and (max-width: 320px){
  #image-section{
      width: 100%;
      height: auto;
      
    };
  } */

/* home page  */

.homeBackImg {
  /* background-image: url("../src/Assets/Images/HomeFrank.png"); */
  background-image: url("../src/Assets/Images/hero-min.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* object-fit: cover; */
  height: 33.25rem;
}


/* input type file */
input::file-selector-button {
  font-weight: bold;
  background-color: dodgerblue;
  color: white;
  /* padding: 0.5em; */
  border: none;
  border-radius: 3px;
  cursor: pointer;
}



/* AboutUs Page */

.imgAbout {
  height: auto;
  width: 100%;

}




.paragraph {
  text-align: justify;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif !important;
}

.h1About {
  text-align: left;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif !important;
  margin-bottom: 25px
}

.h3About {
  text-align: left;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif !important;
}

.h4About {
  text-align: left;
  font-family: 'DM Sans', sans-serif !important;
}

.ulAbout {
  list-style-type: square;
  text-align: left;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif !important;
}

.blueCard {
  margin-top: 80px;
  background-color: #0E4672;
  color: #fff;
  border-radius: 5px;
}

.blueCardParagraph {
  text-align: left;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif !important;
}

.h6,
.h2 {
  text-align: left;
  margin-bottom: 20px;
}

.bookNow {
  margin-top: 10px;
}

/* Imprint Page */
.inputImprint {
  border-style: dashed;
  border-color: #ccc;
  outline: none;
  box-shadow: none;
}

.h6Imprint,
.paraImprint {
  font-weight: bold;
}

.h2Imprint {
  font-weight: 600
}

.black {
  background-color: #F5F5F5;
  padding: 10px;
  margin-bottom: 15px;
}

.border {
  border-style: dashed;
}

.shadowBox-title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.shadowBox-content {
  text-align: center;
  margin-bottom: 40px;
  font-size: 17px;
}

.iconImprint {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.icon-HnW {
  height: 60px;
  width: 50px;
  margin-top: 20px;
}

/* bussiness-summary */
.summary-label {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16.9714px;
  line-height: 22px;
  color: #455E72;
}

.passanger-details {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18.8286px;
  line-height: 27px;
  color: #1F3648
}

.return-way {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  color: #0E4672
}

/*   new Term & condition Page */

.text-header {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000
}

.text-termcondition {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000
}

.main-heads {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  color: #0E4672;
}

/* Admin-first-page */
.Box-width {
  width: "364px"
}

/* ----------------vehiclePageIssues--------------------- */
.people-bag {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 3px;
}

.vehicle-type {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.capsule-Number {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000
}

.ride-booking {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #0E4672
}

.ride-color {
  background-color: #0B2D48;
}
.grid3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.ttlwidth {
  font-weight: bolder;
  font-size: 18px;
  border: solid 1px;
  border-radius: 8px;
  padding: 1rem;
  background-color: rgb(186, 186, 186);
}

/* .App {
  height: 100;
  width: 100;
}

.map-container {
  height: 100%;
  width: 100%;
} */




input[type="file"] {
  margin-left: 10px;
  border: 1px solid #b5bcc7;
  width: 360px;
  vertical-align: middle;
  margin-bottom: 10px;
  border: none;
}

input[type="file"]::-webkit-file-upload-button {
  background: #EEF8FF;
  border-radius: 6px;
  color: #1F3648;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 10px;
  border: none;
  float: right;
}

.tableId a {
  color: #0072CB;
  text-decoration: none;
  cursor: pointer;
}

.tableId a:hover {
  color: #0E4672;
  text-decoration: underline;
  cursor: pointer;
}

.icon-round {
  border-radius: 50%;
}



/* css */

.btn100{
  height: 100%;
  width: 100%;
  border-radius: 0% !important;
}
.selects{
  height: 40px;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.error{
  font-size: 12px;
  color: #d32f2f;
  margin-left: 14px;
  margin-top: 3px;
}























@media (max-width: 1024px) {

}

@media (max-width: 991px) {
  .grid3{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .grid3{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .label-b {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #000000;
  }
}

@media (max-width: 330px) {
    
}
